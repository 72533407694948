<template>
  <div>

    <!-- == ONE == -->
    <vs-row>
      <vs-col vs-w="12">
        <div class="vx-col w-full mb-base insight">
          <vx-tooltip color="primary" text="Executive Insights" position="left">
            <vx-card title="Executive Insights ">
              To be entered by data scientists
            </vx-card>
          </vx-tooltip>
        </div>
      </vs-col>
    </vs-row>
    <br>


    <!-- sunburst chart -->
    <vs-row>

      <vs-col vs-w="12">
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <!--            <Skeletor v-if="is_loading"
              circle
              size="400"
         
              :shimmer="true"
            />
            <v-chart v-else class="chart" :option="category_option" v-once/>-->
            <v-chart class="chart" :option="category_option" v-once/>

          </vs-col>
        </vs-row>
      </vs-col>

    </vs-row>


    <!--  Image screenshot: to be submit later  -->
    <!--    <vs-row>-->
    <!--      <vs-col vs-w="12">-->
    <!--        <h4>Employee Report</h4>-->
    <!--        <img src="../assets/images/sample/1.png">-->
    <!--      </vs-col>-->
    <!--    </vs-row>-->

    <!--  Image screenshot: to be submit later  -->
    <!--    <vs-row>-->
    <!--      <vs-col vs-w="12">-->
    <!--        <h4>Leadership Report</h4>-->
    <!--        <img src="../assets/images/sample/2.png">-->
    <!--      </vs-col>-->
    <!--    </vs-row>-->


    <vs-row>

      <vs-col vs-w="12">
        <div>
         <h3>Well-being Index Report </h3>
          <p>
            <a href="https://www.isabel.sg/Well-being-amazon/report">
            https://www.isabel.sg/Well-being-amazon/report
          </a>
          </p>
        </div>
      </vs-col>

    </vs-row>

  </div>
</template>


<style scoped>
.chart {
  height: 100vh;
}
body {
  margin: 0;
}
</style>

<script>
import Vue from 'vue'
import 'v-skeletor/dist/v-skeletor.css';
import { Skeletor } from 'v-skeletor';

import DateRangePicker from 'vue-mj-daterangepicker'
import 'vue-mj-daterangepicker/dist/vue-mj-daterangepicker.css'
// cust: https://vuejsexamples.com/vue-js-date-range-picker-with-multiples-ranges-and-presets/

Vue.use(DateRangePicker)
import FxModal from './new-dash/blocks/Modal'


//core
import {use} from "echarts/core";

//render
import {CanvasRenderer} from "echarts/renderers";

//chart
import {PieChart, SunburstChart, LineChart} from "echarts/charts";

//component
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent
} from "echarts/components";

import VChart, {THEME_KEY} from "vue-echarts";

//install
use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  SunburstChart,
  GridComponent,
  LineChart,
  ToolboxComponent
]);


import VueApexCharts from "vue-apexcharts";
import apexChatData from "./apexChartData.js";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import "flatpickr/dist/flatpickr.css";
import flatPickr from "vue-flatpickr-component";
import moment from 'moment'



export default {
  data() {

    return {

      is_loading: true,

      date_from: moment().subtract(2, 'months').utc().format('YYYY-MM-DD HH:mm:ss'),

      date_to: moment().utc().format('YYYY-MM-DD HH:mm:ss'),

      //Graph-1
      category_option: {
        toolbox: {
          show: false,
          feature: {
            dataView: {readOnly: false},
            restore: {},
            saveAsImage: {}
          }
        },
        title: {
          text: 'Employee Interests',
          subtext: '',
          textStyle: {
            fontSize: 18,
            align: 'center'
          },
          subtextStyle: {
            align: 'center'
          },
          sublink: ''
        },
        series: {
          label: {
            formatter: '\n{b}\n\n{c}%'
          },
          emphasis: {
            label: {
              formatter: '\n{b}\n\n{c}%'
            }
          },
          downplay: {
            label: {
              formatter: '\n{b}\n\n{c}%'
            }
          },
          type: 'sunburst',
          data: [

          ],  //<--- data
          radius: [0, '95%'],
          sort: undefined,
          emphasis: {
            focus: 'ancestor'
          },
          levels: [
            {},
            {
              r0: '15%',
              r: '35%',
              itemStyle: {
                borderWidth: 2
              },
              label: {
                rotate: 'tangential'
              }
            },
            {
              r0: '35%',
              r: '70%',
              label: {
                align: 'right'
              }
            },
            {
              r0: '70%',
              r: '72%',
              label: {
                position: 'outside',
                padding: 3,
                silent: false
              },
              itemStyle: {
                borderWidth: 3
              }
            }
          ]
        }
      },


    }
  },

  components: {
    Skeletor,
    VueApexCharts,
    StatisticsCardLine,
    flatPickr,
    // 'v-chart': ECharts
    // ECharts
    VChart,
    FxModal
  },

  provide: {
    [THEME_KEY]: "light"
  },

  computed: {

  },

  mounted() {

  },

  methods: {

  },

  created() {
    this.$store.dispatch('dashboard/initializeDashboard2', {

      org_id: this.$store.state.AppActiveUser.org_id,
      date_from: this.date_from,
      date_to: this.date_to,

    }).then((data)=>{

      this.category_option.series.data = data.insights.interest_areas
      this.is_loading = false
      this.$vs.loading.close();

    })

  },
};
</script>
<style>
.btn_dashboard {
  float: right;
  position: relative;
  left: 43px;
  top: -3px;
}
</style>
<style>
.tab_heading_item {
  font-weight: bold;
  width: 100%;
  text-align: center;

}

.tab_heading_item_parent {
  background: white;
  cursor: pointer;
}

.tab_heading_active {

  background: #1c54a2;
  padding: 10px;
  color: white;
  display: inline-block;
  border-radius: 10px;
}

.tab_heading_container {
  margin-bottom: 20px;
  padding: 5px;
  background: #ffffff;
}

.title_range {
  color: silver;
}



  /* Static background */
  .v-skeletor {
    background-color: #c1c1c1;
  }

  /* 
    If you have theme switching in your app for example
    from light to dark, you can target skeletons under 
    some global theme class or attribute e.g. 
  */
  [data-theme="dark"] .v-skeletor {
    background: #363636;
  }

  /* 
    Text skeleton
    By default skeletor uses fully rounded style for text
    type skeletons, you can change that as you like
  */
  .v-skeletor--text {
    /* Completely square style skeletons */
    border-radius: 0;
  }

  /* Shimmer */
  .v-skeletor:not(.v-skeletor--shimmerless):after {
    /* 
      Change the shimmer color, its a simple 90 deg 
      linear horizontal gradient, adjust it however
      you like.
    */
    background: linear-gradient(
      90deg, 
      rgba(255,255,255,0) 0%, 
      rgba(255,255,255,1) 50%, 
      rgba(255,255,255,0) 100%
    );

    /* Change any css keyframes animation property */
    animation-duration: 2s; 
    animation-timing-function: ease-in-out;
    /* ... */

    /* 
      Or implement your custom shimmer css animation 
      if you want it's pure css no magic happening =)
    */
  }

  /* Default keyframes used in skeletor */
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

</style>
